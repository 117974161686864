import React from "react";
import { Error404 } from "./styles";

const ErrorPage = () => {
  return (
    <div>
      <Error404>Nie ma takiej strony 404</Error404>
    </div>
  );
};

export default ErrorPage;
